<template>
    <div class="scroll-div flex">
        <ts-table-operate style="margin: 0 20px;align-items: center;" hide-search :cols="[]">
            <el-button type="text" icon="el-icon-document" @click="exportInfo()">导出数据</el-button>
            <div style="margin-left: 10px;">筛选：
                <ts-year-select v-model="year" @change="render"></ts-year-select>
            </div>
        </ts-table-operate>
        <div class="flex-center">
            <template v-if="message">
                <ts-error></ts-error>
            </template>
            <template v-else>
                <div :id="nameKey" class="canvas" style="height: 540px;"></div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['compCode', 'baseQuery', 'visitId'],
        data() {
            return {
                nameKey: 'newCorpRatio',
                message: false,

                year: this.$variable.lastYear.key,
                list: [],
            }
        },
        created() {
            this.loading = true
            this.$api.post('company/saleYear/sumRateAndRatioGroupByComNameAndCompNameAndDoseAndSpecByYear', {
                params: {compCode: this.compCode},
                page: {pageNo: 1, pageSize: 10}
            }, {'Visit-Id': this.$help.getVisitId(this.visitId)})
                .then(res => {
                    if (res.code === this.$variable.successCode) {
                        this.list = res.data.records
                        this.render()
                    } else {
                        this.message = true
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        methods: {
            render() {
                const list = this.list.map(item => {
                    return {
                        name: `${item.comName}-${item.unifySpec}`,
                        value: this.$help.parseFloat100(item[`ratio${this.$variable.yearMap[this.year]}`]),
                        amount: item[`amount${this.$variable.yearMap[this.year]}`]
                    }
                }).sort((a, b) => {
                    return b.value - a.value
                }).sort((a, b) => {
                    return a.value - b.value
                })
                // console.log(this.list)
                this.$help.renderBarRotateOnly(this.nameKey, list)
            },
            exportInfo(txt = '确定导出吗', query = {}) {
                this.$store.commit('confirm', {
                    content: txt,
                    callback:(next, back) => {
                        this.$api.post('company/saleYear/exportSumRateAndRatioGroupByComNameAndCompNameAndDoseAndSpecByYear',
                            this.$help.joinObj({params:{compCode: this.compCode}}, query))
                            .then(res => {
                                if (!(res.success || res.code === 'N0001')) {
                                    next()
                                    this.$store.commit('error', res.message)
                                } else if (res.code === 'N0001') {
                                    next()
                                    this.exportInfo(res.message, {model: true})
                                } else {
                                    this.$help.getExportStatus(res.data.id, data => {
                                        if (data === true) {
                                            next()
                                            this.$store.commit('success', '导出成功')
                                        } else {
                                            back()
                                            this.$store.commit('error', data)
                                        }
                                    })
                                }
                            })
                            .catch(back)
                    },
                })
            },
        }
    }
</script>